export default {
    name: 'doubleVerify',
    init() {
        const dv = 'https://pub.doubleverify.com/signals/pub.js#ctx=17778638&cmp=DV587414&signals=ids,bsc,vlp,tvp,abs,qt_loaded';
        profads.ads2.utils.loadScript({src: dv, handler: this.onScriptLoad, async: true});
    },
    onScriptLoad() {
        if(!webmd.ads2.dvSignalLoaded) {
            webmd.ads2.dvSignalLoaded = true;
            console.log('%c doubleVerify callBack', 'background: #FF00A8; color: #fff');
            let fn = () => {};
            if(typeof PQ === 'object') {
                PQ.cmd.push(() => {
                    PQ.loadSignals(['ids', 'bsc', 'vlp', 'tvp', 'abs'], fn);
                });
            }
        }
    }
};
